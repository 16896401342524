/*//*/
html {
  background-color: black;
  background: #86d3d3;
  margin: 0;
  padding: 0;
}

/*//*/
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400&display=swap');

@font-face {
  font-family: 'popcore';
  src: url('./Fonts/Pop Core.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'cartoon';
  src: url('./Fonts/Cartoon.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'diary';
  src: url('./Fonts/Celina Diary.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

body {
  z-index: 10;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(to right, rgb(146, 201, 233), rgb(118, 130, 145));
  width: 100%;
  height: 100%;
}

/*/A/*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.align-self{
  align-self: center!important;
}

.add-message-button {
  background-color: #ff4081;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 10px;
}

.add-message-button:hover {
  background-color: #e91e63;
}


/*/B/*/
.background {
  position: relative;
  width: 100%;
  height: 300px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  overflow: hidden;
}

.btn-primary {
  background-image: linear-gradient(to right, rgb(183, 214, 168), rgb(61, 141, 88));
  font-style: normal;
}

.btn-primary:hover {
  background-image: linear-gradient(to right, rgb(61, 141, 88), rgb(183, 214, 168));
  font-style: normal;
}

.b-items {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100% !important;
}

.bottom {
  bottom: 0 !important;
}


/*/C/*/

.card-width {
  width: 345px !important;
}

.card {
  background-image: linear-gradient(to right, rgb(177, 177, 182), rgb(124, 120, 92));
  color: yellowgreen;
}

.card-no-background {
  background: transparent !important;
}

.c-items {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100% !important;
}

.card-col-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.check-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
}

.card-container-frame {
  width: 100%;
  padding: 5%;
  margin: 2.5%;
}

.card-frame-tags {
  width: 80%;
  height: 125px !important;
  overflow: scroll !important;
}

.center-a {
  justify-content: center !important;
  display: inline-flex !important;
}

.container {
  position: relative;
}

.colors-card-btn {
  margin: 0px 15px 0px 0px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right, rgb(175, 167, 166), rgb(15, 13, 1));
  border-style: solid;
}

.c-pointer {
  cursor: pointer;
}

.color-font {
  color: #cbfacd;  
}

.closed-tickets-table {
  width: 100%;
  border-collapse: collapse;
}

.closed-tickets-table th,
.closed-tickets-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}


.closed-tickets-table th,
.closed-tickets-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.closed-tickets-table th {
  background: linear-gradient(to bottom, #6c757d, #495057);
  color: white;
}

.closed-tickets-table tr:hover {
  background-color: #f8f9fa;
}

/*/D/*/

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.display-wh{
  display: block!important;
  max-height:100vh; 
  width: 100vw;
}

/*/E/*/


.empty-tickets {
  text-align: center;
  color: #6c757d;
  padding: 20px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/*/F/*/



.frame-admin-ticket {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background: linear-gradient(to bottom right, #f3f4f6, #e2e4e6);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-section {
  margin-bottom: 20px;
}


.flex-inline {
  display: inline-flex !important;
}

.frame-left-sample {
  width: auto;
  height: 250px;
}


.frame-right-sample {
  width: auto;
}

.font-size-2 {
  font-size: 0.89em;
}

.f-s-1 {
  font-size: 0.5em;
}

.flame-container {
  position: absolute;
  bottom: 20px;
  right: 2%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
}

.flame {
  width: 100%;
  height: 100%;
  animation: flicker 1s infinite alternate;
}

@keyframes flicker {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}


/*/G/*/

.gear-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 1.5em;
}

.glass-effect {
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.glass-effect:before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 25%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0.5) 75%,
      rgba(255, 255, 255, 0) 100%);
  transform: rotate(-30deg);
  z-index: 150;
  pointer-events: none;
  animation: glass-shine 2s linear infinite;
}

@keyframes glass-shine {
  0% {
    transform: rotate(-30deg) translateX(-200%);
  }

  100% {
    transform: rotate(-30deg) translateX(200%);
  }
}


.glass-card {
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
  border-radius: 15px !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  color: #fff !important;
  text-align: center !important;
  transition: transform 0.2s !important;
}

.glass-card:hover {
  transform: scale(1.05) !important;
}

.glass-card h5 {
  color: #f0f0f0 !important;
  font-weight: bold !important;
}

.glass-card p {
  color: #f0f0f0 !important;
  margin-bottom: 0.75rem !important;
}

.glass-card strong {
  color: #ffd700 !important;
  font-size: 1.2rem !important;
}

@media (max-width: 768px) {
  .glass-card {
    margin-bottom: 20px;
  }
}

/*/H/*/

.h-80 {
  height: 77px !important;
}

.h-95 {
  height: 95% !important;
}

.hover-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.1s ease-out;
}

.hover-image {
  width: 100%;
  height: 100%;
  transition: transform 0.1s ease-out;
}

.home-background {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #141e30, #243b55);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.floating-container {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 300px;
}


.floating-message {
  position: absolute;
  background: rgba(255, 255, 255, 0.65);
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  color: #333;
  opacity: 0;
  animation: floatMessage 10s linear forwards;
}

@keyframes floatMessage {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/*/I/*/
.img-p-c {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.item-justify-center {
  justify-content: center;
  justify-items: center;
  justify-self: center;
}

.input-group {
  display: auto !important;
}

.img-card-no {
  object-fit: cover !important;
  height: 200px !important;
  border-radius: 10px;
}

.insert-area-center {
  inset-area: center
}

.informative-text {
  font-family: 'Noto Sans', sans-serif;
  font-size: 1.1rem;
  line-height: 1.7;
  color: #333;
}

/*/J/*/

.justify-center {
  justify-content: center !important;
  justify-items: center !important;
}

.just-background{

}

/*/K/*/
/*/L/*/

@keyframes ledEffect {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.led-background {
  background: linear-gradient(270deg, #cbfacd, #5d8097, #00aeff, #a0fcf7);
  background-size: 600% 600%;
  animation: ledEffect 5s ease infinite;
}

.loading-container {
  width: 80%;
  height: 50px;
  align-self: center;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.loading-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 1s ease-in-out;
}

.loading-text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/*/N/*/

.no-show {
  display: none !important;
}

.no-border {
  border: none !important;
}

.no-background {
  background-color: transparent !important;
  background-image: none !important;
  border: none;
}

.no-scroll {
  overflow: hidden !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

.neon-text {
  font-size: 24px;
  color: #fff;
  text-shadow:
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 20px #ff00ff,
    0 0 30px #ff00ff,
    0 0 40px #ff00ff,
    0 0 55px #ff00ff,
    0 0 75px #ff00ff;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow:
      0 0 5px #fff,
      0 0 10px #fff,
      0 0 20px #ff00ff,
      0 0 30px #ff00ff,
      0 0 40px #ff00ff,
      0 0 55px #ff00ff,
      0 0 75px #ff00ff;
  }

  to {
    text-shadow:
      0 0 10px #fff,
      0 0 20px #ff00ff,
      0 0 30px #ff00ff,
      0 0 40px #ff00ff,
      0 0 50px #ff00ff,
      0 0 65px #ff00ff,
      0 0 100px #ff00ff;
  }
}

/*/M/*/

.modal {
  z-index: 9509999 !important;
}

.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.m-tb-5 {
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}

.m-w-5 {
  margin: 2.5%;
}

.m-t-1 {
  margin-top: 10px;
}

.m-10 {
  margin-right: 25px;
}

.m-50 {
  margin-top: 2.5%;
  margin-bottom: 5%
}

.modal-backdrop {
  z-index: 1000000 !important;
}

/*/O/*/
.offcanvas {
  z-index: 11115000 !important;
}

/*/P/*/

.p-absolute {
  position: absolute;
}

.p-static {
  position: static;
}

.p-relative {
  position: sticky !important;
}

.p-fixed {
  position: fixed !important;
  position-area: center !important;
  overflow: none;
  z-index: 150000;
}

.parallax {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  will-change: transform;
}

.p-5 {
  padding: 2.5%;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  background: linear-gradient(to bottom right, #6c757d, #343a40);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.pagination button.active {
  background: linear-gradient(to bottom right, #495057, #343a40);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.pagination button:hover {
  transform: scale(1.1);
}

/*/Q/*/


/*/R/*/

.rounded-font {
  font-family: 'Baloo 2', cursive;
  font-size: 2rem;
}

.r-items {
  inset-area: right;
}

/*/S/*/

.style-btn-a {
  background-image: linear-gradient(to right, rgb(183, 214, 168), rgb(61, 141, 88));
  font-style: normal;
}

.style-btn-b {
  background-image: linear-gradient(to right, rgb(56, 121, 206), rgb(115, 224, 152));
  font-style: normal;
}

.style-btn-b:hover {
  background-image: linear-gradient(to right, rgb(32, 68, 114), rgb(119, 148, 128));
  font-style: normal;
}

.style-btn-c {
  background-image: linear-gradient(to right, rgb(206, 74, 56), rgb(231, 218, 142));
  font-style: normal;
}

.style-main-a {
  background-image: linear-gradient(to top, rgb(127, 198, 211), rgb(230, 222, 163)) !important;
  align-items: center;
}

.style-main-b {
  background-image: linear-gradient(to bottom, rgb(240, 126, 255), rgb(194, 198, 199)) !important;
  align-items: center;
}

.style-main-c {
  background-image: linear-gradient(to right bottom, rgb(85, 154, 172), rgb(46, 5, 51)) !important;
  align-items: center;
}

.style-card-back-a {
  background-image: linear-gradient(to right bottom, rgb(233, 211, 217), rgb(46, 5, 51)) !important;
  align-items: center;
}

.style-card-back-b {
  background-image: linear-gradient(to right bottom, rgb(229, 185, 135), rgb(159, 235, 37)) !important;
  align-items: center;
}

.style-card-back-c {
  background-image: linear-gradient(to right bottom, rgb(172, 207, 216), rgb(62, 104, 91)) !important;
  align-items: center;
}

.style-card-back-v {
  background-image: linear-gradient(to right bottom, rgb(135, 228, 98), rgb(116, 153, 155)) !important;
  align-items: center;
}

.style-divcard-a {
  position: relative;
  margin: 0px 15px 0px 0px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right bottom, rgb(233, 211, 217), rgb(46, 5, 51)) !important;
  border-style: solid;
}

.style-divcard-b {
  position: relative;
  margin: 0px 15px 0px 0px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right bottom, rgb(229, 185, 135), rgb(159, 235, 37)) !important;
  border-style: solid;
}

.style-divcard-c {
  position: relative;
  margin: 0px 15px 0px 0px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right bottom, rgb(172, 207, 216), rgb(62, 104, 91)) !important;
  border-style: solid;
}


.style-divbtn-a {
  position: relative;
  margin: 0px 15px 0px 0px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right, rgb(183, 214, 168), rgb(61, 141, 88));
  border-style: solid;
}


.style-divbtn-b {
  margin: 0px 15px 0px 0px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right, rgb(56, 121, 206), rgb(115, 224, 152));
  border-style: solid;
}

.style-divbtn-c {
  margin: 0px 15px 0px 0px;
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right, rgb(206, 74, 56), rgb(231, 218, 142));
  border-style: solid;
}

.stars {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
}

.scroll-y{
  overflow: auto!important;
  height: 100%!important;
}

.star {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  opacity: 0.7;
  animation: rise 5s linear infinite, twinkle 3s infinite ease-in-out alternate;
}

@keyframes rise {
  from {
    transform: translateY(100vh) scale(0.5);
    opacity: 0.2;
  }

  to {
    transform: translateY(-100vh) scale(1.2);
    opacity: 1;
  }
}

@keyframes twinkle {
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

/*/T/*/

.t-0 {
  top: 0;
}

.ticket-card {
  height: 107px !important;
  overflow: scroll;
}

.transparent {
  background-color: transparent !important;
  border: none !important;
}

.t-center {
  text-align: center;
}

.text-container {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.txt-white {
  color: #f3f3f3;
}

.txt-center {
  align-self: center;
  text-align: center;
  display: contents;
}

.tickets-wrapper {
  background: linear-gradient(to bottom right, #ffffff, #e9ecef);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/*/W/*/

.w-270 {
  width: 290px !important;
}

.w-90 {
  width: 90% !important;
}

.w-50 {
  width: 50% !important;
}

.w-85 {
  width: 85% !important;
}



/*/Z/*/

.z-10 {
  z-index: 50 !important;
}

.z-25 {
  z-index: 120 !important;
}

.z-100 {
  z-index: 150 !important;
}

.z-modal {
  z-index: 9155000 !important;
}

.z-modal-con {
  z-index: 9155000 !important;
}

.logo-animation {
  display: block;
  margin: auto;
  animation: pulse 3s infinite ease-in-out;
}

.pulse-circle {
  animation: scale-pulse 3s infinite ease-in-out;
}

.rotating-rays {
  animation: rotate-rays 6s linear infinite;
}

/* Animations */
@keyframes scale-pulse {

  0%,
  100% {
    r: 200;
  }

  50% {
    r: 220;
  }
}

@keyframes rotate-rays {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}


.login-container {
  position: relative;
  width: 300px;
  margin: auto;
  text-align: center;
}

.login-form {
  transition: transform 0.5s ease-in-out;
}

.login-form.registering {
  transform: translateY(-20px);
}